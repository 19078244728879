.bs-stepper .step-trigger {
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 20px;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.5;
	color: #6c757d;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: none;
	border-radius: 0.25rem;
	transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.box-step {
	display: contents;
}

.bs-stepper .step-trigger:disabled,
.bs-stepper .step-trigger.disabled {
	pointer-events: none;
	opacity: 0.65;
}

.bs-stepper .step-trigger:focus {
	color: theme-color('primary');
	outline: none;
}

.bs-stepper .step-trigger:hover {
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0.06);
}

@media (max-width: 520px) {
	.bs-stepper .step-trigger {
		flex-direction: column;
		padding: 10px;
	}
}

.bs-stepper-label {
	display: inline-block;
	margin: 0.25rem;
}

.bs-stepper-header {
	display: flex;
	align-items: center;
}

@media (max-width: 520px) {
	.bs-stepper-header {
		margin: 0 -10px;
		text-align: center;
	}
}

.bs-stepper-line,
.bs-stepper .line {
	flex: 1 0 32px;
	min-width: 1px;
	min-height: 1px;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.12);
}

@media (max-width: 400px) {
	.bs-stepper-line,
	.bs-stepper .line {
		flex-basis: 20px;
	}
}

.bs-stepper-circle {
	display: inline-flex;
	align-content: center;
	justify-content: center;
	width: 2em;
	height: 2em;
	padding: 0.5em 0;
	margin: 0.25rem;
	line-height: 1em;
	color: #fff;
	background-color: #6c757d;
	border-radius: 1em;
}

.active .bs-stepper-circle {
	background-color: theme-color('colorSelected');
}

.bs-stepper-content {
	padding: 0 20px 20px;
}

@media (max-width: 520px) {
	.bs-stepper-content {
		padding: 0;
	}
}

.bs-stepper.vertical {
	display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
	flex-direction: column;
	align-items: stretch;
	margin: 0;
}

.bs-stepper.vertical .bs-stepper-pane,
.bs-stepper.vertical .content {
	display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade),
.bs-stepper.vertical .content:not(.fade) {
	display: block;
	visibility: hidden;
}

.bs-stepper-pane:not(.fade),
.bs-stepper .content:not(.fade) {
	display: none;
}

.bs-stepper .content.fade,
.bs-stepper-pane.fade {
	visibility: hidden;
	transition-duration: 0.3s;
	transition-property: opacity;
}

.bs-stepper-pane.fade.active,
.bs-stepper .content.fade.active {
	visibility: visible;
	opacity: 1;
}

.bs-stepper-pane.active:not(.fade),
.bs-stepper .content.active:not(.fade) {
	display: block;
	visibility: visible;
}

.bs-stepper-pane.dstepper-block,
.bs-stepper .content.dstepper-block {
	display: block;
}

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,
.bs-stepper:not(.vertical) .content.dstepper-none {
	display: none;
}

.vertical .bs-stepper-pane.fade.dstepper-none,
.vertical .content.fade.dstepper-none {
	visibility: hidden;
}
