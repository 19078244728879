@import '../login-colors';

#login-page {
	background: linear-gradient(to right, rgb(35, 37, 38), rgb(65, 67, 69));
	height: 100vh;
	width: 100% !important;
	overflow: hidden;
	.banner {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 1;
		display: block;
		background-image: url('../../assets/img/banners/banner_login.png');
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		// -webkit-filter: blur(2px);
		// -moz-filter: blur(2px);
		// -o-filter: blur(2px);
		// -ms-filter: blur(2px);
		// filter: blur(2px);
		width: 100%;
		height: 110%;
	}
	.content {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 9999;
	}
	.container {
		// display: flex;
		// align-items: center;
		display: grid;
		grid-template-columns: repeat(8, 1fr);
		grid-template-rows: repeat(7, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		justify-content: center;
		min-height: 100vh;
		.screen {
			grid-area: 4 / 1 / 8 / 4;
			background: rgba(255, 255, 255, 0.7);
			position: relative;
			height: 360px;
			width: 360px;
			box-shadow: 30px 50px 30px -20px rgba(0, 0, 0, 0.25);
			.screen__content {
				text-align: center;
				z-index: 1;
				height: 110%;
				form {
					padding: 15px;
					padding-top: 40px;
					button {
						font-size: 14px;
						margin-top: 20px;
						padding: 16px 20px;
						border-radius: 26px;
						line-height: 27px;
						// text-transform: uppercase;
						font-weight: 700;
						display: flex;
						align-items: center;
						width: 100%;
						background-color: #c3151b !important;
						color: white;
						border-radius: 20px;
						cursor: pointer;
						transition: 0.2s;
						&:active,
						&:focus,
						&:hover {
							outline: none;
							border-color: $btn-actions !important;
						}
						> div {
							width: 100%;
							text-align: center;
							> div {
								float: right;
							}
						}
					}
					.img-container {
						text-align: center;
						text-shadow: 0px 0px 30px #2758a8;
					}
					.input-group-prepend {
						align-items: center;
						position: relative;
						left: 15px;
					}
					.input-group {
						padding: 20px 0px;
						position: relative;
						background: rgba(201, 76, 76, 0);
						color: #f2f2f2;
						width: 95%;
						.invalid-feedback {
							position: absolute;
							bottom: 0;
						}
					}
					input {
						border: none;
						color: #00498f;
						border-bottom: 2px solid #f2f2f2;
						background: rgba(201, 76, 76, 0);
						padding: 10px;
						padding-left: 24px;
						font-weight: 700;
						transition: 0.2s;
						&:active,
						&:focus,
						&:hover {
							background: rgba(255, 255, 255, 0.1);
							box-shadow: none;
						}
						&::-webkit-input-placeholder {
							color: #00498f;
						}
						&::after {
							background-color: red;
						}
					}
				}
			}
		}
	}
}
