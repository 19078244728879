.cr-inputForm {
   
    
    .group 			  { 
        position:relative; 
      }
      .input 				{
        font-size:18px;
        padding:3px 10px 3px 5px;
        display:block;
        width: 100%;
        border:none !important;
        border-bottom:1px solid #757575;
      }
      .input:focus 		{ outline:none; border: none;}
      
      /* LABEL ======================================= */
      label 				 {
        color:#999; 
        font-size:18px;
        font-weight:normal;
        position:absolute;
        pointer-events:none;
        left:5px;
        top:10px;
        transition:0.2s ease all; 
        -moz-transition:0.2s ease all; 
        -webkit-transition:0.2s ease all;
      }
      
      /* active state */
      .input:focus ~ label, .input:valid ~ label 		{
        top:-20px;
        font-size:14px;
        color:#5264AE;
        outline: none !important;
        border: none !important;
      }
      
      /* BOTTOM BARS ================================= */
      .bar 	{ position:relative; display:block; width:100%; }
      .bar:before, .bar:after 	{
        content:'';
        height:2px; 
        width:0;
        bottom:1px; 
        position:absolute;
        background:#5264AE; 
        transition:0.2s ease all; 
        -moz-transition:0.2s ease all; 
        -webkit-transition:0.2s ease all;
      }
      .bar:before {
          left: 50%;
      }
      .bar:after {
          right: 50%;

      }
      
      /* active state */
      .input:focus ~ .bar:before, .input:focus ~ .bar:after {
        width:50%;
      }
      
      /* HIGHLIGHTER ================================== */
      .highlight {
        position:absolute;
        height:60%; 
        
        width:100px; 
        top:25%; 
        left:0;
        pointer-events:none;
        opacity:0.5;
      }
      
      /* active state */
      .input:focus ~ .highlight {
        -webkit-animation:inputHighlighter 0.3s ease;
        -moz-animation:inputHighlighter 0.3s ease;
        animation:inputHighlighter 0.3s ease;
      }
}